import { Injectable } from "@angular/core";
import { Repair } from "../../models/repair.model";
import { Equipment } from "../../models/equipment.model";
import { DamageType } from "../../models/mode.model";
import { catchError, distinctUntilChanged, firstValueFrom } from "rxjs";
import { MasterDataService } from "../../services/master-data.service";
import { RepairExclusion } from "../../models/repair-exclusion.model";
import { ContainerGrade } from "../../models/container-grade.model";
import { ThirdPartyIndicator } from "../../models/tpi.model";
import { RepairLocation } from "../../models/repair-location.model";
import { CodeDescription } from "../../models/code-description.model";
import { Currency } from "../../models/currency.model";

@Injectable()
export class RepairLineItemsDataService{
constructor(private masterDataService:MasterDataService){
}
async getRepairExclusions(repairModeCode: string):Promise<RepairExclusion[]> {
    const repairExclusions = await firstValueFrom(this.masterDataService.getRepairExclusions(repairModeCode)
      .pipe(catchError((error) => {
        throw error;
      }), distinctUntilChanged()));
    return repairExclusions;
  }

  /**
   * Section get container grades
   */
  async getContainerGrades():Promise<ContainerGrade[]> {
    const containerGrades=await firstValueFrom(this.masterDataService.getContainerGrades()
      .pipe(catchError((error) => {
        throw error;
      }), distinctUntilChanged()));
      return containerGrades;
  }
  /**
   * section to get tpi codes
   * 
   */
async getTPIs():Promise<ThirdPartyIndicator[]> {
    const tpis = await firstValueFrom(this.masterDataService.getRepairTpiCodes()
      .pipe(catchError((error) => {
        throw error;
      }), distinctUntilChanged()));
    return tpis;
  }
  /**
   * Section to get repir Loactions
   */
   async getRepairLocations(damageType: DamageType):Promise<RepairLocation[]> {
    const repairLocations = await firstValueFrom(this.masterDataService.getRepairLocations()
      .pipe(catchError((error) => {
        throw error;
      }), distinctUntilChanged()));
    return repairLocations.filter(x => x.damageType === damageType)
  }
  /**
   * Section to get damage codes
   */
   async getDamages(damageType: DamageType):Promise<CodeDescription[]> {
    const damages = await firstValueFrom(this.masterDataService.getDamageCodes(damageType)
      .pipe(catchError((error) => {
        throw error;
      }), distinctUntilChanged()));
   return damages
  }

  /**
   * Section to get repair codes
   * @param shopCode 
   * @param damageType 
   * @param equipment 
   * @returns 
   */
  async getRepairs(shopCode: string, repairModeCode: string, equipment: Equipment, damageType: DamageType, currency:Currency):Promise<Repair[]> {
    const repairs = await firstValueFrom(this.masterDataService.getRepairCodes(repairModeCode, shopCode, currency.exchangeRateFromUsd ?? 0)
      .pipe(catchError((error) => {
        throw error;
      }), distinctUntilChanged()));
    const activeRepairs = repairs.filter(repair => repair.isActive && !repair.isSuspended);
    return this.filterRepairCodesByEquipmentManufacturerAndModel(activeRepairs, equipment, damageType)
  }
  private filterRepairCodesByEquipmentManufacturerAndModel(repairs: Repair[], equipment: Equipment, damageType: DamageType): Repair[] {
    let filteredRepairs = repairs;
    if (equipment.equipmentTypeCode === 'REEF' && damageType === DamageType.NON_STRUCTURAL) {
      switch (equipment.reeferUnitManufacturerName?.toUpperCase()) {
        case "STARCOOL":
          filteredRepairs = repairs.filter(x => (x.code.startsWith("0") || x.code.startsWith("1")))
          break;
        case "DAIKIN":
          filteredRepairs = repairs.filter(x => (x.code.startsWith("0") || x.code.startsWith("2")))
          break;
        case "CARRIER":
          if (equipment.reeferUnitModel?.toUpperCase() === 'NATURALINE') {
            filteredRepairs = repairs.filter(x => (x.code.startsWith("0") || x.code.startsWith("4")))
          } else {
            filteredRepairs = repairs.filter(x => (x.code.startsWith("0") || x.code.startsWith("5")))
          }
          break;
        case "THERMO KING":
          let reeferModel = equipment.reeferUnitModel?.toUpperCase()
          if (reeferModel === 'TNE508' || reeferModel === 'CRR40' || reeferModel === 'MAGNUM' || reeferModel==="MAGNUM+") {
            filteredRepairs = repairs.filter(x => (x.code.startsWith("0") || x.code.startsWith("6")))
          } else {
            filteredRepairs = repairs.filter(x => (x.code.startsWith("0")))
          }
          break;
        case "STAR FREEZE":
          filteredRepairs = repairs.filter(x => (x.code.startsWith("0") || x.code.startsWith("7")))
          break;
      }
    }
    return filteredRepairs;
  }
}