import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Renderer2,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { Theme } from '../../theme/theme';
import { ThemeService } from '../../theme/theme.service';
import { HeaderComponent } from '../../layouts/header/header.component';
import { SideMenuComponent } from '../../layouts/side-menu/side-menu.component';
import '@maersk-global/mds-components-core/mc-button';
import '@maersk-global/mds-components-core/mc-label';
import '@maersk-global/mds-components-core/mc-toast';
import { BehaviorSubject, ReplaySubject, takeUntil } from 'rxjs';
import { ToastrService } from '../../services/toastr-service';
import { TelemetryService } from '../../services/telemetry.service'; // Import the TelemetryService

@Component({
  selector: 'app-shell',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HeaderComponent,
    SideMenuComponent,
  ],
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ShellComponent implements AfterViewInit {
  title = 'AEMS';
  clickEvent: string = '';
  buttonLabel: string = 'Display Message';
  currentTheme: Theme = Theme.LIGHT; // Set the default theme to light
  statusCode: string = '';
  toastMessage$ = new BehaviorSubject<{ message: string, type: string }>({ message: '', type: '' });
  warningToastDuration$ = new BehaviorSubject<number>(5000);
  @ViewChild('warningToastTrigger', { read: ElementRef, static: false })
  warningToastTrigger!: ElementRef<HTMLElement>;

  @ViewChild('successToastTrigger', { read: ElementRef, static: false })
  successToastTrigger!: ElementRef<HTMLElement>;

  @ViewChild('errorToastTrigger', { read: ElementRef, static: false })
  errorToastTrigger!: ElementRef<HTMLElement>;
  private faro: any; // Store the faro instance
  toggleMenu: boolean = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private themeService: ThemeService,
    private renderer: Renderer2,
    public toastrService: ToastrService,
    private telemetryService: TelemetryService // Inject the TelemetryService
  ) {
    this.themeService.setTheme(this.currentTheme, this.renderer);
    this.title = $localize`${this.title}`;
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    const toastTriggers = {
      warning: this.warningToastTrigger,
      success: this.successToastTrigger,
      error: this.errorToastTrigger
    };
    this.toastrService.init(toastTriggers);
    this.toastrService.getToastMessage()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(toast => {

        this.toastMessage$.next(toast);
      });
      this.toastrService.getToastDuration()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(duration => {
        this.warningToastDuration$.next(duration);
      });
      //console.log('Calling logScreenSize from ShellComponent'); // Add this line to verify
      this.telemetryService.logScreenSize(); // Log screen size using the TelemetryService
    //this.registerRumTelemetry();
  }
  /*public closeToast(): void {
    this.toastrService.closeToast(); // Call closeToast from ToastrService
  }*/
  
  onMenuClicked() {
    this.toggleMenu = !this.toggleMenu;
  }

  /*private registerRumTelemetry() {
    const faro = RumInit({
      app: {
        name: environment.MopRumAppName,
        version: '1.0.0',
        environment: environment.ENVIRONMENT
      },
      apiKey: environment.MopRumApiKey,
      useSendBeacon: true,
      sso_user: true,
      ignoreUrls: ['.*localhost.*', '.*pensieve.*'],
      instrumentations: {
        interactions: false,
        console: {
          disabledLevels: [LogLevel.TRACE, LogLevel.INFO, LogLevel.DEBUG]
        }
      },
      debug: false, // false: Prevent showing log,error,etc captured in browser console
      traceLog: false, // Sends Traces to Loki ( For easy querying of traces)
      propagateTraceHeaderCorsUrls: [new RegExp('.*api.*')]
    });
    let user = AuthHelper.user;
    faro.api.setUser({
      id: user?.userId.toString(),
      email: user?.emailAddress,
      username: user?.username
    })
    const screenSize = `${window.screen.width}x${window.screen.height}`;
    faro.api.pushLog(['screen-size', screenSize], {
      level: LogLevel.INFO // Only use supported properties here
    });
  }
    public logFileEvent(eventType: string, fileCount: number) {
      this.faro.api.pushLog([eventType], {
        level: LogLevel.INFO,
        file_count: fileCount // Custom attribute for file count
      });
}*/
}