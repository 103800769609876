import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Output, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import "@maersk-global/mds-components-core/mc-icon";
import "@maersk-global/mds-components-core/mc-tooltip";
import { Theme } from '../../theme/theme';
import { ThemeService } from '../../theme/theme.service';
import { AuthHelper } from '../../auth/auth-helper';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  

})
export class HeaderComponent {
  @Output() menuClicked=new EventEmitter();
  currentTheme: Theme = Theme.LIGHT; // Set the default theme to light
  isSideNavOpen=false;
  constructor(private themeService:ThemeService,private renderer:Renderer2) {

  }
  
  onToggleThemeClicked() {
    this.currentTheme = this.currentTheme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT;
    this.themeService.setTheme(this.currentTheme, this.renderer);
  }
  onLogoutClicked(){
    AuthHelper.logout();
  }
  onHamburgerClicked(){
    this.isSideNavOpen=!this.isSideNavOpen;
    this.menuClicked.emit();
  }

}
