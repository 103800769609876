import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { catchError, map, of, tap } from 'rxjs';
import { Equipment } from '../../../../models/equipment.model';
//import { WarningSharedService } from '../../warning-shared-service';
import { ToastrService } from '../../../../services/toastr-service';

export function duplicateRepairCodeValidator(
  index: number,
  equipment: Equipment | null,
  toastrService: ToastrService,
  //warningSharedService: WarningSharedService,
): ValidatorFn {
  return (control: AbstractControl) => {
    var repairCode = control.value as string;
    if (!repairCode)
      return null;
    if (!equipment)
      return null;
    if (!equipment.lastWorkOrderRepairCodes)
      return null;
    let validatorName = duplicateRepairCodeValidator.name + index;
    let duplicateRapair = equipment.lastWorkOrderRepairCodes.find(x => x == repairCode)
    if (duplicateRapair) {
      toastrService.showToastr(
        'Duplicate work order found with previous empty move.',
        'warning',
        60000
      );
    }

    return null;
  }
}
