<form>
  <div class="aems-card m-b-12">
    @if(workOrderMode === 'view'){
      <div class="flex-row-no-space man-hours m-b-12">
        <div class="man-hours-text">Total Man Hours</div>
        <div class="man-hours-data">{{totalManHoursSummary()}}</div>
      </div>
    }
    <div class="m-b-12">
      <mc-table [data]="dataCalculationSummary" [columns]="columnsCalculationSummary" [customstyles]="rowStyles"
        [hidecolumns]="hidecolumns" fit="small" sortdisabled="true">
        @if(apiCallStatus()=="IN_PROGRESS"){
        @for(item of dataCalculationSummary;track $index){
        <div [slot]="item.id +'_shopCostUsd'">
          <ngx-skeleton-loader [theme]="{'margin-bottom':'0px',height:'15px',width:'60px'}" appearance="line" />
        </div>
        <div [slot]="item.id +'_shopCostLocal'">
          <ngx-skeleton-loader [theme]="{'margin-bottom':'0px',height:'15px',width:'60px'}" appearance="line" />
        </div>
        }
        }
        @if(repairLineItemsAndManhourFormValid()===false){
        @for(item of dataCalculationSummary;track $index){
        <div [slot]="item.id +'_shopCostUsd'">
          Invalid Data
        </div>
        <div [slot]="item.id +'_shopCostLocal'">
          Invalid Data
        </div>
        }
        }

      </mc-table>
    </div>
    <div class="m-b-12">
      <mc-table [data]="dataResponsibilityTotal" [columns]="columnsResponsibilityTotal" [customstyles]="rowStyles"
        [hidecolumns]="hidecolumns" fit="small" sortdisabled="true">
        @if(apiCallStatus()=="IN_PROGRESS"){
        @for(item of dataResponsibilityTotal;track $index){
        <div [slot]="item.id +'_shopCostUsd'">
          <ngx-skeleton-loader [theme]="{'margin-bottom':'0px',height:'15px',width:'60px'}" appearance="line" />
        </div>
        <div [slot]="item.id +'_shopCostLocal'">
          <ngx-skeleton-loader [theme]="{'margin-bottom':'0px',height:'15px',width:'60px'}" appearance="line" />
        </div>
        }
        }
        @if(repairLineItemsAndManhourFormValid()===false){
        @for(item of dataResponsibilityTotal;track $index){
        <div [slot]="item.id +'_shopCostUsd'">
          Invalid Data
        </div>
        <div [slot]="item.id +'_shopCostLocal'">
          Invalid Data
        </div>
        }
        }
      </mc-table>
    </div>
    <mc-table [data]="dataWithTaxTotal" [columns]="columnsWithTaxTotal" [customstyles]="rowStyles"
      [hidecolumns]="hidecolumns" fit="small" sortdisabled="true">
      @if(apiCallStatus()=="IN_PROGRESS"){
      @for(item of dataWithTaxTotal;track $index){
      <div [slot]="item.id +'_shopCostUsd'">
        <ngx-skeleton-loader [theme]="{'margin-bottom':'0px',height:'15px',width:'60px'}" appearance="line" />
      </div>
      <div [slot]="item.id +'_shopCostLocal'">
        <ngx-skeleton-loader [theme]="{'margin-bottom':'0px',height:'15px',width:'60px'}" appearance="line" />
      </div>
      }
      }
      @if(repairLineItemsAndManhourFormValid()===false){
      @for(item of dataWithTaxTotal;track $index){
      <div [slot]="item.id +'_shopCostUsd'">
        Invalid Data
      </div>
      <div [slot]="item.id +'_shopCostLocal'">
        Invalid Data
      </div>
      }
      }
    </mc-table>
    <div class="m-t-12">
      @if(apiCallStatus()==="IN_PROGRESS"){
      <ngx-skeleton-loader [theme]="{'margin-bottom':'0px',height:'15px',width:'100px'}" appearance="line" />
      }@else{
      @if(repairLineItemsAndManhourFormValid()){
      <mc-button class="view-details-btn" variant="plain" appearance="primary" fit="small" padding="none"
        (click)="toggleDetails()">
        View Details
      </mc-button>
      }@else{
      Invalid Data
      }
      }
    </div>
    @if(showDetails){
    <mc-modal heading="Calculation Details" dimension="large" [open]="openCalculationDetails"
      (closed)="onCalculationDetailsClose()">
      <app-cwo-total-cost-details [totalCostDetails]="cost()"></app-cwo-total-cost-details>
    </mc-modal>
    }
  </div>

</form>