import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { DamageAndRepairForm } from '../../../../form-model/damage-and-repair.form.model';
import { RepairExclusion } from '../../../../models/repair-exclusion.model';
import { InputSignal } from '@angular/core';
export function mutualExclusiveRepairCodeValidator(
    repairExclusions: InputSignal<RepairExclusion[]>
): ValidatorFn {
	return (control: AbstractControl) => {
		let damageAndRepairForm = control as FormGroup<DamageAndRepairForm>;
        const exclusionSet = createExclusionSet(repairExclusions());
		let repairLineItems = damageAndRepairForm.controls.repairLineItems;
        let conflictingCodes: string[] = [];

        let selectedRepairCodes: string[] = repairLineItems.controls.map(
			(repairLineItem) => repairLineItem.controls.repairCode.value
		);

		// Initialize a flag for mutually exclusive repair codes found
		let mutualExclusiveRepairCodeFound = false;

		 // Check if any two repair codes are mutually exclusive
         for (let i = 0; i < selectedRepairCodes.length; i++) {
            for (let j = i + 1; j < selectedRepairCodes.length; j++) {
                let repairCode1 = selectedRepairCodes[i];
                let repairCode2 = selectedRepairCodes[j];

                // Check if the two repair codes are mutually exclusive using the precomputed exclusion set
                if (exclusionSet.has(`${repairCode1}:${repairCode2}`)) {
                    mutualExclusiveRepairCodeFound = true;
                    conflictingCodes = [repairCode1, repairCode2];
                    break; // Break out of the inner loop
                }
            }
            if (mutualExclusiveRepairCodeFound) break; // Break out of the outer loop
        }

		if (mutualExclusiveRepairCodeFound) {
            const message = `Repair codes ${conflictingCodes.join(' and ')} cannot be added in the same work order. Choose one or the other`;
			let existingErrors = repairLineItems.controls[repairLineItems.controls.length - 1].errors;
            if(existingErrors){
                existingErrors['mutualExclusiveRepairCode'] = { value: message };
            }
            else{
                repairLineItems.controls[repairLineItems.controls.length - 1].setErrors({
                    mutualExclusiveRepairCode: { value: message },
                });
            }
			return { mutualExclusiveRepairCode: { value: message } };
		} else {
			repairLineItems.controls.forEach((repairLineItem) => {
				if (repairLineItem.errors) {
					repairLineItem.errors['mutualExclusiveRepairCode'] = null;
				}
			});
			return null;
		}
	};
}
// Create a set of mutually exclusive pairs for fast lookup
function createExclusionSet(exclusions: RepairExclusion[]): Set<string> {
    const exclusionSet = new Set<string>();
    
    exclusions.forEach(exclusion => {
        // Add both (repairCode1, excludedRepairCode) and (excludedRepairCode, repairCode1) to the set
        exclusionSet.add(`${exclusion.repairCode}:${exclusion.excludedRepairCode}`);
        exclusionSet.add(`${exclusion.excludedRepairCode}:${exclusion.repairCode}`);
    });

    return exclusionSet;
}
