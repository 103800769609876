import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, input, signal } from '@angular/core';
import { AbstractControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FileUploadComponent } from '../../aems-file-upload/file-upload.component';
import { CreateWorkOrderRepairPartsComponent } from '../repair-parts/cwo-repair-parts.component';
import { RepairLineItemForm } from '../../../form-model/cwo-repair-line-item.form.model';
import { IMcTypeaheadData } from '@maersk-global/mds-components-core/mc-typeahead/types';
import { ContainerGrade } from '../../../models/container-grade.model';
import { Repair } from '../../../models/repair.model';
import { Part } from '../../../models/part.model';
import { utils } from '../../../helper/utils';
import { combineLatest, ReplaySubject, takeUntil } from 'rxjs';
import { typeaheadCodeExistValidator } from '../../../validators/typeahead-code-exist.validator';
import { MasterDataService } from '../../../services/master-data.service';
import { Equipment } from '../../../models/equipment.model';
import { Mode } from '../../../models/mode.model';
import { containerGradeValidator } from './validators/container-grade.validator';
import { repairSuspendedValidator } from './validators/repair-suspended.validator';
import { Shop } from '../../../models/shop.model';
import { duplicateRepairCodeValidator } from './validators/duplicate-repair-code-validator';
import { WorkOrderService } from '../../../services/work-order.service';
import { WarningSharedService } from '../warning-shared-service';
import { ptiStatusValidator } from './validators/pti-status.validator';
import { CodeDescription } from '../../../models/code-description.model';
import { RepairLocation } from '../../../models/repair-location.model';
import { ThirdPartyIndicator, getTPIChar } from '../../../models/tpi.model';
import { MaintenanceOrderLineItem } from '../../../models/maintenance-order-line-item';
import { nonEmptyReeferRepairWarning } from './validators/nonempty-reefer-repair-warning.validator';
import { Currency } from '../../../models/currency.model';
import { ToastrService } from '../../../services/toastr-service';

@Component({
	selector: 'app-repair-line-item',
	standalone: true,
	imports: [ReactiveFormsModule, FileUploadComponent, CreateWorkOrderRepairPartsComponent, NgxSkeletonLoaderModule],
	templateUrl: './repair-line-item.component.html',
	styleUrl: './repair-line-item.component.scss',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RepairLineItemComponent implements OnInit {
	@Input({ required: true }) repairLineItem!: FormGroup<RepairLineItemForm>;
	@Input() existingRepairLineItem: MaintenanceOrderLineItem | undefined;
	mode = input.required<Mode>();
	shop = input.required<Shop>();
	controlId=input.required<string>();
	equipment = input.required<Equipment>();
	containerGrades = input.required<ContainerGrade[]>();
	workOrderCurrency = input.required<Currency | undefined>();
	damages = input.required<CodeDescription[]>();
	repairs = input.required<Repair[]>();
	repairLocations = input.required<RepairLocation[]>();
	tpiCodes = input.required<ThirdPartyIndicator[]>();
	@Input({ required: true }) index!: number;
	@Output() repairLineItemRemoved = new EventEmitter<number>();
	parts: Part[] = [];
	loadingParts = signal<boolean>(false);
	repairLineItemAmountWithoutParts: number = 0;
	partsTotalAmount: number = 0;

	// Controls data
	damageCodesTypeahead: IMcTypeaheadData[] = []
	repairCodesTypeahead: IMcTypeaheadData[] = []
	repairLocationCodesTypeahead: IMcTypeaheadData[] = []
	tpiCodesTypeahead: IMcTypeaheadData[] = []

	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	constructor(private masterDataService: MasterDataService,
		 private toastrService: ToastrService){}
		//private warningSharedService: WarningSharedService) {
	ngOnInit(): void {
		this.damageCodesTypeahead = this.mapCodeDescriptionToTypeaheadData(this.damages());
		this.repairCodesTypeahead = this.mapCodeDescriptionToTypeaheadData(this.repairs());
		this.repairLocationCodesTypeahead = this.mapCodeDescriptionToTypeaheadData(this.repairLocations());
		this.tpiCodesTypeahead = this.mapTPICodeToTypeaheadData(this.tpiCodes());
		this.calculateRepairItemTotalCost();
		this.addValidatorsToDamageCode();
		this.addValidatorsToRepairCode();
		this.addValidatorsToRepairLocationCode();
		this.addValidatorsToTpiCode();
		if (this.existingRepairLineItem) {
			this.fillForm(this.existingRepairLineItem)
		}
	}
	ngOnDestroy(): void {
		//this.removeWarnings();
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	isControlInErrorState(control: AbstractControl | null): boolean | null {
		if (control?.invalid && control.touched) {
			return true;
		}
		return null;
	}
	isDamageAndRepairCodeValid(): boolean | undefined {
		return this.repairLineItem.controls.damageCode.valid && this.repairLineItem.controls.repairCode.valid;
	}
	/**
	 * @description Event handler when the repair code is selected from the typeahead control
	 * @param event selected element from the typeahead control in template
	 * Resets the part array and parts form array control if the selected repair code does not have any parts
	 * Add validators and set value of Man Hr/Pc, pieces , and mat cost/pc control of a repair line item
	 * Checks if the selected repair code has any part associated with it, if yes, fetches the parts
	 */
	onRepairCodeOptionSelected(event: FocusEvent) {
		this.parts = [];
		this.repairLineItem.controls.parts.clear();
		let repairCode=(event.target as HTMLInputElement).value;
		let selectedRepair = this.repairs().find((repair) => repair.code === repairCode);
		if (selectedRepair) {
			this.addRequiredAndMaxValidators(this.repairLineItem, selectedRepair);
			this.repairLineItem.controls.manHoursPerPiece.setValue(utils.formatNumberToTwoDigitDecimal(selectedRepair.maxLabourHours));
			this.repairLineItem.controls.pieces.setValue(1);
			this.repairLineItem.controls.materialCostPerPiece.setValue(0);
			if (selectedRepair.allowParts === true) {
				this.getParts(selectedRepair);
			}
		}
	}
	private onRepairCodePrefilledDuringEdit(repair: Repair) {
		this.parts = [];
		this.addRequiredAndMaxValidators(this.repairLineItem, repair);
		if (repair.allowParts === true) {
			this.getParts(repair);
		}
	}

	onRepairPartsTotalChanged(event: number) {
		this.partsTotalAmount = event;
		this.repairLineItem.controls.itemTotalCost.setValue(utils.numberFormatter.format(this.repairLineItemAmountWithoutParts + this.partsTotalAmount));
	}

	onRemoveRepairLineItemClicked() {
		this.repairLineItemRemoved.emit(this.index);
	}

	private addRequiredAndMaxValidators(
		repairLineItem: FormGroup<RepairLineItemForm>,
		selectedRepair: Repair
	) {
		let piecesControl = repairLineItem.controls.pieces;
		let manHoursPerPieceControl = repairLineItem.controls.manHoursPerPiece;
		let materialCostPerPieceControl = repairLineItem.controls.materialCostPerPiece;
		piecesControl.clearValidators();
		piecesControl.addValidators([Validators.required, Validators.pattern(/^(0|[1-9]\d*)$/), Validators.max(selectedRepair.maxQuantity)]);
		piecesControl.updateValueAndValidity();
		manHoursPerPieceControl.clearValidators();
		manHoursPerPieceControl.addValidators([Validators.required, Validators.pattern(/^\d*\.?\d+$/), Validators.max(selectedRepair.maxLabourHours)]);
		manHoursPerPieceControl.updateValueAndValidity();
		materialCostPerPieceControl.clearValidators();
		materialCostPerPieceControl.addValidators([Validators.required, Validators.pattern(/^\d*\.?\d+$/), Validators.max(selectedRepair.maxMaterialCost)]);
		materialCostPerPieceControl.updateValueAndValidity();
	}
	/**
	 *
	 * @param repairLineItem Private methods regions
	 */
	private calculateRepairItemTotalCost() {
		combineLatest([
			this.repairLineItem.controls.pieces.valueChanges,
			this.repairLineItem.controls.materialCostPerPiece.valueChanges]
		)
			.pipe(takeUntil(this.destroyed$))
			.subscribe(([pieces, materialCostPerPiece]) => {
				this.repairLineItemAmountWithoutParts = pieces * materialCostPerPiece;
				this.repairLineItem.controls.itemTotalCost.setValue(utils.numberFormatter.format(this.repairLineItemAmountWithoutParts + this.partsTotalAmount));
			});
	}

	private addValidatorsToDamageCode() {
		let damageCode = this.repairLineItem.controls.damageCode;
		damageCode.clearValidators();
		damageCode.addValidators([
			Validators.required,
			typeaheadCodeExistValidator(this.damageCodesTypeahead),
		]);
		damageCode.updateValueAndValidity();
	}

	/**
	 *
	 * @param repairLineItem the line item to which this repair code belongs to
	 * @description Add validators to the repair code control
	 */
	private addValidatorsToRepairCode() {
		let repairCodeControl = this.repairLineItem.controls.repairCode;
		repairCodeControl.clearValidators();
		repairCodeControl.addValidators([
			Validators.required,
			typeaheadCodeExistValidator(this.repairCodesTypeahead),
			containerGradeValidator(this.equipment(), this.containerGrades(), this.mode()),
			repairSuspendedValidator(this.repairs()),
			ptiStatusValidator(this.index, this.equipment(), this.toastrService)
		]);

		if (this.equipment()?.isLastMoveGateInEmpty) {
			repairCodeControl.addValidators(duplicateRepairCodeValidator(this.index, this.equipment(), this.toastrService))
		}
		
		if (!this.equipment()?.isContainerEmpty) {
			repairCodeControl.addValidators(nonEmptyReeferRepairWarning(this.index, this.equipment(), this.repairs(), this.toastrService));
		}
		repairCodeControl.updateValueAndValidity();


	}

	/**
	 *
	 * @param repairLineItem the line item to which this repair location code belongs to
	 * @description Add validators to the repair location code control
	 */
	private addValidatorsToRepairLocationCode() {
		let repairLocationCode = this.repairLineItem.controls.repairLocationCode;
		repairLocationCode.clearValidators();
		repairLocationCode.addValidators([
			Validators.required,
			typeaheadCodeExistValidator(this.repairLocationCodesTypeahead),
		]);
		repairLocationCode.updateValueAndValidity();
	}

	/**
	 *
	 * @param repairLineItem the line item to which this tpi code belongs to
	 * @description Add validators to the tpi code control
	 */
	private addValidatorsToTpiCode() {
		let tpiCode = this.repairLineItem.controls.tpiCode;
		tpiCode.clearValidators();
		tpiCode.addValidators([Validators.required, typeaheadCodeExistValidator(this.tpiCodesTypeahead)]);
		tpiCode.updateValueAndValidity();
	}

	private getParts(repairCode: Repair) {
		this.loadingParts.set(true);
		this.masterDataService
			.getRepairParts(this.mode().code, repairCode.code, this.shop().maintenanceShopCode,this.workOrderCurrency()?.exchangeRateFromUsd??0)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((parts) => {
				this.parts = parts;
				this.loadingParts.set(false);
			});
	}

	/*private removeWarnings() {
		this.warningSharedService.deleteWarning("duplicateRepairCodeValidator" + this.index);
		this.warningSharedService.deleteWarning("ptiStatusValidator" + this.index);
		this.warningSharedService.deleteWarning("exceptionalReapirValiator" + this.index);
	}*/

	private mapCodeDescriptionToTypeaheadData(data: CodeDescription[]): IMcTypeaheadData[] {
		return data.map((codeDescription) => {
			return {
				label: codeDescription.code,
				sublabel: codeDescription.description,
				value: codeDescription.code,
			};
		});
	}

	private mapTPICodeToTypeaheadData(data: ThirdPartyIndicator[]): IMcTypeaheadData[] {
		return data.map((codeDescription) => {
			return {
				label: getTPIChar(codeDescription.code),
				sublabel: codeDescription.displayText,
				value: codeDescription.code,
			};
		});
	}

	private fillForm(item: MaintenanceOrderLineItem) {
		let repair = this.repairs().filter(repair => repair.code === item.maintenanceTypeCode)[0]
		this.onRepairCodePrefilledDuringEdit(repair);
		this.repairLineItem.patchValue({
			damageCode: item.equipmentDamageCode,
			repairCode: item.maintenanceTypeCode,
			repairLocationCode: item.maintenanceRepairLocationCode,
			tpiCode: getTPIChar(item.thirdPartyIndicator),
			pieces: item.repairQuantity,
			materialCostPerPiece: item.materialCostPerPiece,
			manHoursPerPiece: item.manHoursPerPiece,
			itemTotalCost: utils.numberFormatter.format((item.repairQuantity * item.materialCostPerPiece) + item.maintenanceOrderParts.reduce((accumulator, currentPart) => accumulator + (currentPart.unitPrice * currentPart.unitQuantity), 0))
		})
	}

}
