@if(readOnly){
  @if(inputTags.length>0){
  <div class="tagged-input-parent">
  <mc-tag>{{ inputTags[0] }}</mc-tag>
  @if(inputTags.length>1){
  <mc-popover trigger="click" position="bottom-left">
    <div slot="trigger" class="overflow-count">+{{inputTags.length-1}}</div>
    @if(inputTags){
    <div class="tags-container">
      @for(tag of inputTags;track tag){
        @if($index>0){
        <mc-tag>{{ tag }}</mc-tag>
        }
      }
    </div>
    }
  </mc-popover>
}
</div>
  }@else {
    <div>-</div>
  }
}@else{
<div class="tagged-input-parent">
  <mc-input style="flex-grow: 1;" [hiddenlabel]="hiddenLabel" [label]="label" [labelposition]="labelPosition" [fit]="fit" ngDefaultControl [(ngModel)]="inputControlValue"
    (keydown.enter)="onEnterPressed($event)" placeholder="Hit enter to add mutiple values" (blur)="onBlur()" [attr.invalid]="hasDuplicateTag?true:null">
    @if(hasDuplicateTag){
      <span slot="errormessage">Duplicate entry</span>
      }
  </mc-input>
  @if(overflowCount>0){
  <mc-popover trigger="click" position="bottom-left">
    <div slot="trigger" class="overflow-count">+{{overflowCount}}</div>
    @if(inputTags){
    <div class="tags-container">
      @for(tag of inputTags;track tag){
        <mc-tag withaction (dismiss)="removeTag($index)">{{ tag }}</mc-tag>
      }
    </div>
    }
  </mc-popover>
}
</div>
}
