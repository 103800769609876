import { Injectable } from "@angular/core";
import { Equipment } from "../../../models/equipment.model";
import { Mode } from "../../../models/mode.model";
import { Shop } from "../../../models/shop.model";

/**
 * Service to filter repair modes depending on different paramters of an equipment
 * https://maersk-tools.atlassian.net/wiki/spaces/AAEMS/pages/183034545581/Filtering+modes+based+on+Equipment+properties
 */
@Injectable()
export class ModeFilterService {

    /**
     * @param equipment The equipment
     * @param modes List of all modes without any filtering
     * @returns List of valid modes
     */
    public filterModes(equipment: Equipment, modes: Mode[],shop:Shop): Mode[] {
        let validModes: string[] = [];

        if (this.isRedeliveryContainer(equipment, shop)) {
            validModes = this.addRedeliveryModes(equipment);
        }
        else if (this.isNeutralizationContainer(equipment)) {
            validModes = this.addNeutralizationModes(equipment);
        }
        else if (this.isNormalActiveContainer(equipment, shop)) {
            validModes = this.addModesBasedOnEquipmnentTypes(equipment);
        }
        else if (equipment.equipmentTypeParentCode === "GENS") {
            validModes = this.addGensetModes()
        }
        return modes.filter(mode => validModes.includes(mode.code))
    }

    private isRedeliveryContainer(equipment: Equipment, shop: Shop): boolean{
        return equipment.equipmentTypeParentCode==="CONT" 
                && (equipment.isTaggedForRedelivery??false) 
                && this.isRedeliveryShop(shop) 
                && (equipment.equipmentIsActive === false);
    }

    private isNeutralizationContainer(equipment: Equipment): boolean {
        const totalLossDamageCode: number=1;
        return equipment.equipmentTypeParentCode==="CONT"  
                && this.isMaerskOwned(equipment) 
                && equipment.damageCode === totalLossDamageCode;
    }

    private isNormalActiveContainer(equipment: Equipment, shop: Shop): boolean {
        return equipment.equipmentTypeParentCode === "CONT" 
                && (equipment.equipmentIsActive??false) 
                && (this.isRedeliveryShop(shop) === false);
    }

    public isRedeliveryShop(shop: Shop): boolean {
        return shop.locationCode?.toUpperCase().startsWith("NEAGA") === true;
    }

    private addRedeliveryModes(equipment: Equipment): string[] {
        let modeCodes: string[] = [];
        switch (equipment.equipmentTypeCode) {
            case "REEF":
                modeCodes.push("82");
                break;
            case "DRY":
                modeCodes.push("83");
                break;
            case "FLAT":
                modeCodes.push("84");
                break;
            case "OPEN":
                modeCodes.push("85");
                break;
        }
        return modeCodes;
    }

    private addNeutralizationModes(equipment: Equipment): string[] {
        let modeCodes: string[] = [];
        switch (equipment.equipmentTypeCode) {
            case "REEF":
                modeCodes.push("22");
                modeCodes.push("26");
                modeCodes.push("49");
                break;
            case "DRY":
                modeCodes.push("23");
                break;
        }
        return modeCodes;
    }

    private addModesBasedOnEquipmnentTypes(equipment: Equipment): string[] {
        let modeCodes: string[] = [];
        modeCodes.push("70");
        switch (equipment.equipmentTypeCode) {
            case "REEF":
                modeCodes.push("26");
                modeCodes.push("48");
                modeCodes.push("02");
                modeCodes.push("40");
                modeCodes.push("41");
                modeCodes.push("43");
                if (this.isControlledAtmosphereContainer(equipment)) {
                    modeCodes.push("45");
                    modeCodes.push("46");
                }
                break;
            case "DRY":
                modeCodes.push("03");
                break;
            case "FLAT":
                modeCodes.push("04");
                break;
            case "OPEN":
                modeCodes.push("05");
                break;
            case "TANK":
                modeCodes.push("10");
                break;
        }
        return modeCodes;
    }

    private addGensetModes(){
        return ["31","33","71"]
    }

    private isSteelContainer(equipment: Equipment) {
        return ["SST", "STL"].includes(equipment.constructionMaterialCode ?? "");
    }

    private isControlledAtmosphereContainer(equipment: Equipment): boolean {
        if (["CARP", "CASC", "CARF"].includes(equipment.equipmentSubTypeCode ?? "")) {
            return true;
        } else {
            return false;
        }
    }

    private isMaerskOwned(equipment: Equipment): boolean {
        const isOwned = equipment.ownershipTypeCode === "OWN";
        const maerskSubsidiaryCompanyCode = ["CPH", "SUD", "SCL", "HBD", "SEN", "PON", "MSC", "MSK"];
        const isLeasedByMaerskSubsidiary = ["LT", "ST"].includes(equipment.ownershipTypeCode ?? "") && maerskSubsidiaryCompanyCode.includes(equipment.leasingCompanyCode || '');

        return isOwned || isLeasedByMaerskSubsidiary;
    }
}