import {
	ChangeDetectionStrategy,
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	signal
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	FormBuilder,
	Validators,
	ReactiveFormsModule,
	FormControl,
	FormControlStatus,
	FormGroup
} from '@angular/forms';
import isEqual from "lodash-es/isEqual";
import '@maersk-global/mds-components-core/mc-button';
import '@maersk-global/mds-components-core/mc-card';
import '@maersk-global/mds-components-core/mc-input';
import '@maersk-global/mds-components-core/mc-loading-indicator';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MasterDataService } from '../../../services/master-data.service';
import { ToastrService } from '../../../services/toastr-service'; // Ensure you have ToastrService injected
import {
	catchError,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	forkJoin,
	ReplaySubject,
	takeUntil,
} from 'rxjs';
import { workorderAlreadyExistValidator } from './validators/equipment-workorder-exist.validator';
import { equipmentCometValidator } from './validators/equipment-incomet.validator';
import { equipmentMaximumRepairCostValidator } from './validators/equipment-maximum-repair-cost.validator';
import { Shop } from '../../../models/shop.model';
import { Mode, DamageType } from '../../../models/mode.model';
import { Equipment } from '../../../models/equipment.model';
import { locationMismatchValidatorWarning } from './validators/location-mismatch-warning.validator';
import { HeaderForm } from '../../../form-model/cwo-header.form.model';
import { equipmentDetailsValidator } from './validators/equipment-details.validator';
import { ErrorComponent } from '../../error/error.component';
import { Warning } from '../../../models/warning';
//import { WarningSharedService } from '../warning-shared-service';
import { DamageCause } from '../../../models/damage-cause.model';
import { WorkOrderService } from '../../../services/work-order.service';
import { User } from '../../../models/user.model';
import { PrincipalProperty } from '../../../models/principal-property.model';
import { ModeFilterService } from './mode-filter.service';

@Component({
	selector: 'app-cwo-header',
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule, NgxSkeletonLoaderModule, ErrorComponent],
	templateUrl: './cwo-header.component.html',
	styleUrl: './cwo-header.component.scss',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [ModeFilterService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateWorkOrderHeaderComponent implements OnInit, OnDestroy {
	@Input({ required: true }) headerForm!: FormGroup<HeaderForm>;
	@Output() headerFormValid = new EventEmitter<FormControlStatus>();
	@Output() modeChanged = new EventEmitter<Mode>();
	@Output() shopChanged = new EventEmitter<Shop>();
	@Output() equipmentChanged = new EventEmitter<Equipment>();
	@Output() principalPropertyChanged = new EventEmitter<PrincipalProperty>
	@Input() warnings = signal<Warning[]>([]);
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	masterdataLoading = true;
	equipmentDetailLoading = signal<boolean>(false);
	shops: Shop[] = [];
	causes: DamageCause[] = [];
	allModes: Mode[] = [];
	filteredModes: Mode[] = [];
	isThirdPartyDamage = signal<boolean>(false);
	errorControls = signal<string[]>([]);
	constructor(
		private masterDataService: MasterDataService,
		private workOrderService: WorkOrderService,
		private formBuilder: FormBuilder,
		//private warningSharedService: WarningSharedService,
		private toastrService: ToastrService,
		private modeFilter: ModeFilterService
	) { }
	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	ngOnInit(): void {
		this.addControlsToTheFormGroup();
		this.subscribeToShopModeEquipmentAndFormStatusChanges()
		this.getUserAssignedShop();
		this.subscribeToMasterDataApiCalls();
		this.subscribeToValueChanges<string>([
			{
				control: this.headerForm.controls.equipmentNumber,
				func: (controlValue) => {
					this.getEquipmentDetails(controlValue);
				},   
			},
		]);
		this.subscribeToValueChanges<DamageCause>([
			{
				control: this.headerForm.controls.cause,
				func: (value: DamageCause) => {
					if (value.code === 'CONFIRMED_3RD_PARTY') {
						this.isThirdPartyDamage.set(true);
						this.headerForm.controls.thirdPartyPort.addValidators(Validators.required);
					} else {
						this.isThirdPartyDamage.set(false)
						this.headerForm.controls.thirdPartyPort.clearValidators();
						this.headerForm.controls.thirdPartyPort.updateValueAndValidity();
					}
				}
			},
		]);

		this.subscribeToValueChanges<Shop>([
			{
				control: this.headerForm.controls.shopCode,
				func: (value: Shop) => {
					this.headerForm.controls.currency.setValue(value?.maintenanceShopCurrency.currencyCode ?? '');
					this.shopChanged.emit(value);
					let equipmentNumber:string | undefined = this.headerForm.controls.proxyEquipmentNumberForAsyncValidations.value?.equipmentNumber;
					if(equipmentNumber){
						this.getEquipmentDetails(equipmentNumber);
					}
				},
			},
		]);

		this.subscribeToValueChanges<Mode>([
			{
				control: this.headerForm.controls.mode,
				func: (value: Mode) => {
					this.modeChanged.emit(value);
				},
			},
		]);
		this.headerForm.statusChanges.pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe((status) => {
			this.headerFormValid.emit(status);
		});

		this.headerForm.controls.proxyEquipmentNumberForAsyncValidations.statusChanges
			.pipe(takeUntil(this.destroyed$))
			.subscribe((status) => {
				this.headerForm.controls.equipmentNumber.setErrors(
					this.headerForm.controls.proxyEquipmentNumberForAsyncValidations.errors
				);
			});

		this.subscribeToShopOrModeChanges();
	}

	private getEquipmentDetails(equipmentNumber: string) {
		if (this.headerForm.controls.equipmentNumber.valid) {
			this.equipmentDetailLoading.set(true);
			this.filteredModes = [];
			this.masterDataService.getEquipmentDetails(equipmentNumber, this.headerForm.controls.shopCode.value.locationCode).pipe(catchError(err => {
				this.equipmentDetailLoading.set(false);
				throw err;
			})).subscribe((equipment) => {
				if (!equipment) {
					this.headerForm.controls.proxyEquipmentNumberForAsyncValidations.setValue(equipment);
					this.equipmentDetailLoading.set(false);
					return;
				}
				equipment.equipmentNumber = equipmentNumber;
				this.headerForm.controls.proxyEquipmentNumberForAsyncValidations.setValue(equipment);
				this.equipmentChanged.emit(equipment);
				this.equipmentDetailLoading.set(false);
				this.filteredModes = this.modeFilter.filterModes(equipment, this.allModes, this.headerForm.controls.shopCode.value);
				this.headerForm.controls.mode.setValue(this.filteredModes[0]);
				if (equipment.equipmentTypeCode === 'REEF') {
					this.masterDataService.getEquipmentAlarms(equipmentNumber).subscribe(x => {
						equipment.alarms = x;
						this.equipmentChanged.emit(equipment);
					});
				}
				this.masterDataService.getEquipmentPtiStatus(equipmentNumber).subscribe(pti => {
					equipment.ptiStatus = pti;
					this.equipmentChanged.emit(equipment);
				});
				if (equipment.isLastMoveGateInEmpty) {
					this.workOrderService.doesWorkOrderExistForEquipment(equipmentNumber).subscribe(result => {
						if (result.warnings.length > 0) {
							let filteredWarning = result.warnings.filter((warning: { rulesetName: string; code: string; }) => warning.rulesetName === "equipment_number_ruleset" && warning.code === "0005");
							if (filteredWarning && filteredWarning.length > 0) {
								equipment.lastWorkOrderRepairCodes = filteredWarning[0].metadata?.ExistingRepairCodes.split(',');
								this.equipmentChanged.emit(equipment);
							}
						}
					});
				}
			});
		}
	}

	isControlInErrorState(control: FormControl): boolean | null {
		if (control.invalid && control.touched) {
			return true;
		}
		return null;
	}

	get showMacOk(): boolean {
		return this.headerForm.value.proxyEquipmentNumberForAsyncValidations?.equipmentTypeCode === 'REEF'
			&& this.headerForm.value.mode?.damageType === DamageType.STRUCTURAL

	}
	get showBoxOk(): boolean {
		return this.headerForm.value.proxyEquipmentNumberForAsyncValidations?.equipmentTypeCode === 'REEF'
			&& this.headerForm.value.mode?.damageType === DamageType.NON_STRUCTURAL

	}
	private addControlsToTheFormGroup() {
		this.headerForm.addControl('shopCode', this.formBuilder.nonNullable.control<Shop>({} as Shop, Validators.required));
		this.headerForm.addControl('equipmentNumber', this.formBuilder.nonNullable.control<string>('', {
			updateOn: 'blur',
			validators: [Validators.required, Validators.pattern(/^[A-Z]{4}[0-9]{6,9}$/)]
		}));
		this.headerForm.addControl('proxyEquipmentNumberForAsyncValidations', this.formBuilder.control<Equipment | null>(null, {
			validators: [equipmentCometValidator(), equipmentDetailsValidator()],
			asyncValidators: [equipmentMaximumRepairCostValidator(this.masterDataService, this.toastrService),
			]
		}));
		this.headerForm.addControl('thirdPartyPort', this.formBuilder.nonNullable.control<string>(''));
		this.headerForm.addControl('vendorRefNo', this.formBuilder.nonNullable.control<string>('', Validators.maxLength(30)));
		this.headerForm.addControl('cause', this.formBuilder.nonNullable.control<DamageCause>({} as DamageCause, Validators.required));
		this.headerForm.addControl('mode', this.formBuilder.nonNullable.control<Mode>({} as Mode, Validators.required));
		this.headerForm.addControl('currency', this.formBuilder.nonNullable.control<string>(''));
		this.headerForm.addControl('macOk', this.formBuilder.nonNullable.control<boolean>(false));
		this.headerForm.addControl('boxOk', this.formBuilder.nonNullable.control<boolean>(false));
		this.headerForm.addValidators([
			locationMismatchValidatorWarning(this.toastrService),
		]);
	}
	private subscribeToValueChanges<T>(
		param: Array<{
			control: FormControl<T>;
			func: (value: T) => void;
		}>
	): void {
		param.forEach((element) => {
			element.control.valueChanges
				.pipe(distinctUntilChanged(), debounceTime(100), takeUntil(this.destroyed$))
				.subscribe(element.func);
		});
	}

	private setDefaultValueOfControls() {
		this.headerForm.controls.cause.setValue(this.causes[0]);
		this.headerForm.controls.shopCode.setValue(this.shops[0]);
		this.headerForm.controls.currency.setValue(this.shops[0].maintenanceShopCurrency.currencyCode);
	}

	private subscribeToMasterDataApiCalls() {
		forkJoin([
			this.masterDataService.getDamageCauses(),
			this.masterDataService.getRepairModes(),
		]).subscribe(([causes, modes]) => {
			this.causes = causes;
			this.allModes = modes;
			this.setDefaultValueOfControls();
			this.masterdataLoading = false;
		});
	}

	private subscribeToShopOrModeChanges() {
		combineLatest([
			this.headerForm.controls.shopCode.valueChanges,
			this.headerForm.controls.mode.valueChanges
		]).pipe(takeUntil(this.destroyed$), distinctUntilChanged(isEqual))
			.subscribe(([selectedShop, selectedMode]:[Shop,Mode]) => {
				const shopCode: string = selectedShop.maintenanceShopCode;
				const repairMode: string = selectedMode.code;
				this.getShopRepairCostLimitByMode(shopCode, repairMode);
			});
	}

	private subscribeToShopModeEquipmentAndFormStatusChanges() {
		combineLatest([
			this.headerForm.controls.shopCode.valueChanges,
			this.headerForm.controls.mode.valueChanges,
			this.headerForm.controls.proxyEquipmentNumberForAsyncValidations.valueChanges,
			this.headerForm.statusChanges
		]).pipe(takeUntil(this.destroyed$), distinctUntilChanged(isEqual))
			.subscribe(([selectedShop, selectedMode, enteredEquipment, headerFormStatus]: [Shop, Mode, Equipment, FormControlStatus]) => {
				this.principalPropertyChanged.emit({
					Equipment: enteredEquipment,
					Shop: selectedShop,
					Mode: selectedMode,
					HeaderFormStatus: headerFormStatus
				})

			});
	}

	private getUserAssignedShop() {
		const userData = localStorage.getItem('user');
		if (userData === null) {
			return;
		}
		const user: User = JSON.parse(userData);
		this.shops = user.maintenanceShops;
	}

	private getShopRepairCostLimitByMode(shopCode: string, repairMode: string) {
		this.masterDataService.getShopRepairCostLimitByMode(shopCode, repairMode)
			.subscribe(response => {
				const shop = this.shops.find(x => x.maintenanceShopCode === shopCode);
				if (shop) {
					shop.shopRepairCostAllocations = response;
				}
			});
	}
}
