import { AfterViewInit, ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, input, Input, OnDestroy, OnInit, Output, signal } from '@angular/core';
import { FormBuilder, FormControl, FormControlStatus, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { HeaderForm } from '../../../../form-model/cwo-header.form.model';
import { Shop } from '../../../../models/shop.model';
import { Equipment } from '../../../../models/equipment.model';
import { DamageCause } from '../../../../models/damage-cause.model';
import { Mode } from '../../../../models/mode.model';
import { MasterDataService } from '../../../../services/master-data.service';
import '@maersk-global/mds-components-core/mc-input';
import { distinctUntilChanged, firstValueFrom, ReplaySubject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { SharedDataService } from '../../../../services/shared-data.service';
import { MaintenanceOrderResponse } from '../../../../models/maintenance-order-response.model';
import { WorkOrderService } from '../../../../services/work-order.service';
import { first } from 'lodash-es';
import { PrincipalProperty } from '../../../../models/principal-property.model';
@Component({
  selector: 'app-edit-work-order-header',
  standalone: true,
  imports: [ReactiveFormsModule,CommonModule],
  templateUrl: './edit-work-order-header.component.html',
  styleUrl: './edit-work-order-header.component.scss',
  changeDetection:ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EditWorkOrderHeaderComponent implements OnInit,OnDestroy{
  @Input({ required: true }) headerForm!: FormGroup<HeaderForm>;
  workorderId=input.required<string>();
  causes=signal<DamageCause[]>([]);
  workOrder:MaintenanceOrderResponse|undefined;
  equipment:Equipment|null=null;
  selectedMode=signal<Mode>({} as Mode);
  selectedShop=signal<Shop>({} as Shop);
  selectedCause=signal<DamageCause>({} as DamageCause);
  isThirdPartyDamage=signal(false);
  @Output() headerFormValid = new EventEmitter<FormControlStatus>();
	@Output() modeChanged = new EventEmitter<Mode>();
	@Output() shopChanged = new EventEmitter<Shop>();
	@Output() equipmentChanged = new EventEmitter<Equipment>();
	@Output() principalPropertyChanged = new EventEmitter<PrincipalProperty>();
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private formBuilder: FormBuilder,private masterDataService:MasterDataService,
    private sharedDataService:SharedDataService,private workorderService:WorkOrderService
  ){

  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
		this.destroyed$.complete();
  }
  async ngOnInit() {
    this.initForm();
    this.headerForm.controls.cause.valueChanges.subscribe(damageCause=>{
      if( damageCause.code==='CONFIRMED_3RD_PARTY'){
        this.headerForm.controls.thirdPartyPort.addValidators(Validators.required);
        this.headerForm.controls.thirdPartyPort.updateValueAndValidity();
        this.isThirdPartyDamage.set(true)
      }else{
        this.isThirdPartyDamage.set(false)
        this.headerForm.controls.thirdPartyPort.clearValidators();
        this.headerForm.controls.thirdPartyPort.updateValueAndValidity();
      }
    });
    this.headerForm.statusChanges.pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe((status) => {
			this.headerFormValid.emit(status);
		});
    let savedWorkOrder=await firstValueFrom(this.sharedDataService.getWorkOrder());
    let savedEquipment=await firstValueFrom(this.sharedDataService.getEquipment());
    if(savedWorkOrder){
      this.workOrder=savedWorkOrder
    }else{
      this.workOrder=await firstValueFrom(this.workorderService.getWorkOrderById(this.workorderId()));
    }
    if(savedEquipment){
      this.equipment=savedEquipment
    }else{
      this.equipment=await firstValueFrom(this.workorderService.getEquipmentDetails(this.workorderId()));
    }
    await Promise.allSettled([
    this.getAndSetMode(this.workOrder?.maintenanceRepairModeCode??""),
    this.getAndSetShop(this.workOrder?.maintenanceShopCode??"",this.workOrder?.maintenanceRepairModeCode??""),
    this.getAndSetCause()]);
    this.setFormFields()
    this.modeChanged.emit(this.selectedMode());
    this.shopChanged.emit(this.selectedShop());
    if(this.equipment){
    this.equipmentChanged.emit(this.equipment)
    this.principalPropertyChanged.emit({
      Equipment:this.equipment,
      Mode:this.selectedMode(),
      Shop:this.selectedShop(),
      HeaderFormStatus:this.headerForm.status
    })
    }
  } 
  
  isControlInErrorState(control: FormControl): boolean | null {
		if (control.invalid && control.touched) {
			return true;
		}
		return null;
	}

  private initForm(){
    this.headerForm.addControl('shopCode', this.formBuilder.nonNullable.control<Shop>({} as Shop));
		this.headerForm.addControl('equipmentNumber', this.formBuilder.nonNullable.control<string>(''));
		this.headerForm.addControl('proxyEquipmentNumberForAsyncValidations', this.formBuilder.control<Equipment | null>(null,));
		this.headerForm.addControl('thirdPartyPort', this.formBuilder.nonNullable.control<string>(''));
		this.headerForm.addControl('vendorRefNo', this.formBuilder.nonNullable.control<string>(''));
		this.headerForm.addControl('cause', this.formBuilder.nonNullable.control<DamageCause>({} as DamageCause));
		this.headerForm.addControl('mode', this.formBuilder.nonNullable.control<Mode>({} as Mode));
		this.headerForm.addControl('currency', this.formBuilder.nonNullable.control<string>(''));
		this.headerForm.addControl('macOk', this.formBuilder.nonNullable.control<boolean>(false));
		this.headerForm.addControl('boxOk', this.formBuilder.nonNullable.control<boolean>(false));
  }

  private async getAndSetShop(shopCode:string,repairModeCode:string){
    let result = await Promise.all([firstValueFrom(this.masterDataService.getShopsByShopCode(shopCode)),
    firstValueFrom(this.masterDataService.getShopRepairCostLimitByMode(shopCode,repairModeCode))]);
    let shop=result[0]
    shop.shopRepairCostAllocations=result[1];
    this.selectedShop.set(shop);
  }

  private async getAndSetMode(repairModeCode:string){
    let mode=await firstValueFrom(this.masterDataService.getRepairMode(repairModeCode));
    this.selectedMode.set(mode);
  }

  private async getAndSetCause(){
    this.causes.set(await firstValueFrom(this.masterDataService.getDamageCauses()));
    let selectedCause=this.causes().filter(cause=>cause.code==this.workOrder?.maintenanceOrderCause)[0];
    this.selectedCause.set(selectedCause);
  }

  private setFormFields(){
    this.headerForm.setValue({
      shopCode:this.selectedShop(),
      equipmentNumber:this.workOrder?.equipmentNumber??"",
      proxyEquipmentNumberForAsyncValidations:this.equipment,
      thirdPartyPort:this.workOrder?.damagedAt??"",
      vendorRefNo:this.workOrder?.vendorsReference??"",
      cause:this.selectedCause(),
      mode:this.selectedMode(),
      currency:this.workOrder?.isoCurrencyCode??"",
      macOk:this.workOrder?.reeferContainerCondition==="MAC_OK"?true:false,
      boxOk:this.workOrder?.reeferContainerCondition==="BOX_OK"?true:false
    })
  }
}
