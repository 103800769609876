<div [formGroup]="parentForm">
<app-error [formContainer]="parts"></app-error>
<div class="pure-g pure-u-md-24-24 pure-u-lg-23-24 pure-u-xl-23-24 m-b-8 part-container" formArrayName="parts">
    <div [class.error-on-form-group]="part.hasError('partNumberUniqueValidator')" @removeRepairLineItem class="pure-g part p-b-8 p-t-4 p-l-4" [formGroupName]="j" *ngFor="
        let part of parts.controls;
        let j = index
      ">
      <div  class="pure-u-md-12-24 pure-u-lg-6-24 pure-u-xl-6-24 p-r-8 m-b-12">
        <mc-typeahead showlistonfocus="true" maxoptions="20" ngDefaultControl formControlName="partNumber" label="Part Number" placeholder="Type Code" fit="small"
          (optionselected)="onPartOptionSelected($event, part)" [data]="partNumbersTypeAhead"
          [attr.invalid]="
            isControlInErrorState(part.controls.partNumber)"
          >
          @if(part.controls.partNumber.hasError("required")){
            <span slot="errormessage">Required</span>
            }
            @if(part.controls.partNumber.hasError('typeaheadCodeExistValidator'))
            {
            <span slot="errormessage">Invalid Code</span>
            }
        </mc-typeahead>
      </div>
      <div class="pure-u-md-12-24 pure-u-lg-10-24 pure-u-xl-10-24 p-r-8 m-b-12">
           <app-tag-input ngDefaultControl  [readOnly]="false" label="Old Part Serial Number(Optional)"
            [inputTags]="part.controls.orderedSerialNumbers.value" (values)="onTagChanged(part,$event)" >
        </app-tag-input>
      </div>
      <div class="pure-u-md-4-24 pure-u-lg-2-24 pure-u-xl-2-24 p-r-8 m-b-12">
        <mc-input ngDefaultControl label="Pcs" fit="small" formControlName="pieces"
          [attr.invalid]="isControlInErrorState(part.get('pieces'))">
          @if(part.get('pieces')?.hasError('required')){
          <span slot="errormessage">Required</span>
          } @if(part.get('pieces')?.hasError('max')) {
          <span slot="errormessage">Invalid</span>
          }
        </mc-input>
      </div>
      <div class="pure-u-md-4-24 pure-u-lg-3-24 pure-u-xl-3-24 p-r-8 m-b-12">
        <mc-input readonly ngDefaultControl label="Cost/pc" fit="small" formControlName="partCost" [suffix]="shop?.maintenanceShopCurrency?.currencyCode" [attr.invalid]="isControlInErrorState(part.get('partCost'))">
            @if(part.get('partCost')?.hasError('required')){
                <span slot="errormessage">Required</span>
                } @if(part.get('partCost')?.hasError('max')) {
                <span slot="errormessage">Invalid</span>
                }
        </mc-input>
      </div>
      <div style="text-align: right;" class="pure-u-md-3-24 pure-u-lg-3-24 pure-u-xl-3-24 m-t-24 ">
        <mc-button class="delete-part-button m-r-4" label="Button " variant="outlined" appearance="neutral"
          fit="small" icon="trash" hiddenlabel padding="default" (click)="onRemovePartClicked(j)">
        </mc-button>
        @if(j===parts.length-1) {
        <mc-button label="Button " variant="outlined"
          appearance="neutral" fit="small" icon="plus" hiddenlabel
          [disabled]="parentForm.invalid"
          padding="default"
        (click)="onAddPartClicked()">
        </mc-button>
        }
      </div>
    </div>
  </div>
</div>
<mc-modal heading="Delete Part" fit="small" dimension="small" [open]="openDeletePartConfirmationDialog"
   (closed)="onDeletePartConfirmationClosed()">
  <p style="font-size: 0.8rem;">The part details will be deleted permanently. Are you sure you want to delete the part?</p>
  <mc-button slot="secondaryAction" dialogaction="cancel" appearance="neutral" variant="outlined" (click)="onDeletePartConfirmationCancel()">Cancel</mc-button>
  <mc-button slot="primaryAction" appearance="primary" (click)="onDeletePartConfirmation()">Yes</mc-button>
</mc-modal>