import { Injectable } from "@angular/core";
import { RepairLineItemForm } from "../../form-model/cwo-repair-line-item.form.model";
import { FormArray, FormGroup } from "@angular/forms";
import { MaintenanceOrderLineItem } from "../../models/maintenance-order-line-item";
import { AttachedImage } from "../../models/maintenance-order-attached-image";
import { getTPIChar, ThirdPartyIndicator } from "../../models/tpi.model";
import { CreateWorkOrderForm } from "../../form-model/create-work-order.form.model";
import { MaintenanceOrderManHours } from "../../models/maintenance-order-man-hours";
import { MaintenanceOrderRemark } from "../../models/maintenance-order-remark.model";
import { MaintenanceOrderRemarkScope } from "../../models/remark-scope.model";
import { MaintenanceOrderCreateRequest } from "../../models/maintenance-order-create-request.model";
import { AemsFile } from "../../models/aems-file.model";

@Injectable()
export class CreateWorkOrderFormToRequestMapperService {
   
    map(workOrderForm: FormGroup<CreateWorkOrderForm>, tpiCodes: ThirdPartyIndicator[], equipmentTypeCode: string|undefined):MaintenanceOrderCreateRequest {
        const repairLineItems = workOrderForm.controls.damageAndRepairForm.controls.repairLineItems as FormArray<FormGroup<RepairLineItemForm>>;
        const maintananceOrderLineItems: MaintenanceOrderLineItem[] = this.mapRepairLineItemsToMaintenanceOrderLineItems(repairLineItems, tpiCodes);
        let workOrderFormValue = workOrderForm.value;
        let attachment: AttachedImage[] = [];
        let maintenanceOrderManHours: MaintenanceOrderManHours[] = []
        if (workOrderFormValue.attachmentForm && workOrderFormValue.attachmentForm.files && workOrderFormValue.attachmentForm.files.length > 0) {
            attachment = workOrderFormValue.attachmentForm.files.map(file => this.mapAttachments(file))
        }
        let reeferCondition = "";
        if (equipmentTypeCode === "REEF") {
            reeferCondition = "NOT_PROVIDED"
            if (workOrderFormValue.headerForm?.boxOk) {
                reeferCondition = "BOX_OK"
            }
            if (workOrderFormValue.headerForm?.macOk) {
                reeferCondition = "MAC_OK"
            }
        }
        let ordinaryHours: MaintenanceOrderManHours = {
            manHours: workOrderFormValue.manHoursForm?.ordinaryHours,
            manHoursType: "REGULAR"
        };
        let overtimeHours: MaintenanceOrderManHours = {
            manHours: workOrderFormValue.manHoursForm?.totalManHoursOvertime,
            manHoursType: "OVERTIME"
        }
        let doubletimeHours: MaintenanceOrderManHours = {
            manHours: workOrderFormValue.manHoursForm?.totalManHoursDoubletime,
            manHoursType: "DOUBLETIME"
        }
        let miscHours: MaintenanceOrderManHours = {
            manHours: workOrderFormValue.manHoursForm?.totalManHoursMisc,
            manHoursType: "MISCELLANEOUS"
        }
        maintenanceOrderManHours.push(ordinaryHours);
        maintenanceOrderManHours.push(overtimeHours);
        maintenanceOrderManHours.push(doubletimeHours);
        maintenanceOrderManHours.push(miscHours);

        // Handle remarks
        let remarkText = workOrderForm.controls.remarkForm.controls.remark.value
        let remark: MaintenanceOrderRemark[] = [];
        if (remarkText)
            remark.push({ maintenanceOrderRemarkText: remarkText, maintenanceOrderRemarkScope: MaintenanceOrderRemarkScope.ALL });
        return {
            equipmentNumber: workOrderFormValue.headerForm?.equipmentNumber,
            maintenanceShopCode: workOrderFormValue.headerForm?.shopCode?.maintenanceShopCode,
            maintenanceRepairModeCode: workOrderFormValue.headerForm?.mode?.code,
            vendorsReference: workOrderFormValue.headerForm?.vendorRefNo,
            maintenanceOrderCause: workOrderFormValue.headerForm?.cause?.code,
            damagedAt: workOrderFormValue.headerForm?.thirdPartyPort,
            reeferContainerCondition: reeferCondition,
            attachedImages: attachment,
            maintenanceOrderManHours: maintenanceOrderManHours,
            maintenanceOrderLines: maintananceOrderLineItems,
            maintenanceOrderRemarks: remark
        };
    }
    private mapRepairLineItemsToMaintenanceOrderLineItems(repairLineItems: FormArray<FormGroup<RepairLineItemForm>>, tpiCodes: ThirdPartyIndicator[]): MaintenanceOrderLineItem[] {
        return repairLineItems?.controls.map((formGroup) => {
            const formValue = formGroup.value;
            let attachment: AttachedImage[] = [];
            if (formValue.attachments && formValue.attachments.files && formValue.attachments.files.length > 0) {
                attachment = formValue.attachments.files.map(file => this.mapAttachments(file));
            }
            const maintenanceOrderLineItem: MaintenanceOrderLineItem = {
                equipmentDamageCode: formValue.damageCode ?? "",
                maintenanceOrderParts: formValue.parts?.map((part) => ({
                    partNumber: part.partNumber ?? "",
                    unitQuantity: part.pieces ?? 0,
                    unitPrice: part.partCost ?? 0,
                    orderedSerialNumbers: part.orderedSerialNumbers,
                })) || [],
                maintenanceRepairLocationCode: formValue.repairLocationCode ?? "",
                maintenanceTypeCode: formValue.repairCode ?? "",
                thirdPartyIndicator: tpiCodes.find(tpi => getTPIChar(tpi.code) === formValue.tpiCode)?.code ?? "",
                repairQuantity: formValue.pieces ?? 0,
                materialCostPerPiece: formValue.materialCostPerPiece ?? 0,
                manHoursPerPiece: formValue.manHoursPerPiece ?? 0,
                attachedImages: attachment
            };
            return maintenanceOrderLineItem;
        });
    }
    private mapAttachments(file:AemsFile){
        return {
            name: file.displayName??"", 
            path: file.generatedName, 
            extension:file.generatedName.split('.')[1], 
            contentType: file.file?.type??""
        }
    }
}