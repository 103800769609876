import {
	CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import {
	FormsModule, ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import '@maersk-global/mds-components-core/mc-tag';
import '@maersk-global/mds-components-core/mc-input';
import '@maersk-global/mds-components-core/mc-popover';
@Component({
	selector: 'app-tag-input',
	standalone: true,
	imports: [ReactiveFormsModule, CommonModule, FormsModule],
	templateUrl: './tag-input.component.html',
	styleUrl: './tag-input.component.scss',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TagInputComponent implements OnInit {
	@Input() readOnly: boolean = true;
	@Input() inputTags: string[] = [];
	@Input() fit:"small"|"medium"|"large"="small";
	@Input() label:string="Label";
	@Input() labelPosition:"left"|"top"="top";
	@Input() hiddenLabel:boolean=false;
	@Output() values = new EventEmitter<string[]>();
	maxVisibleTags: number = 0; // Maximum number of tags to display before showing overflow
	overflowCount: number = 0;
	hasDuplicateTag: boolean = false;
	inputControlValue = "";
	constructor() {
	}
	ngOnInit(): void {
		this.prefillValues();
	}
	addTag() {
		let value = this.inputControlValue.trim();
		if (value) {
			let itemExists = this.inputTags.includes(value);
			if (!itemExists) {
				this.inputTags.push(value);
				this.inputControlValue = "";
				this.updateOverflow();
			} else {
				this.hasDuplicateTag = true;
			}
		}
	}
   prefillValues(){
	if(this.inputTags.length>0 && !this.readOnly){
		this.inputControlValue=this.inputTags[0];
		this.inputTags.splice(0,1);
	}
	this.updateOverflow();
   }
	removeTag(index: number) {
			this.inputTags.splice(index, 1);
			this.updateOverflow();
			this.emitValues();
	}

	updateOverflow() {
		this.overflowCount = Math.max(0, this.inputTags.length - this.maxVisibleTags);
	}

	onEnterPressed(event: Event) {
		this.hasDuplicateTag = false;
		this.addTag();
		console.log(event)
	}
	onBlur(){
		this.emitValues();
	}

	private emitValues(){
		let value=this.inputControlValue.trim();
		if(value && !this.inputTags.includes(value)){
			this.values.emit(this.inputTags.concat([value]));
		}else{
			this.values.emit(this.inputTags)
		}
	}

}
