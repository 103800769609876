// telemetry.service.ts
import { Injectable } from '@angular/core';
import { LogLevel } from "@grafana/faro-web-sdk";
import { RumInit } from '@maersk-global/telemetry-web-sdk';
import { environment } from '../../environments/environment';
import { AuthHelper } from '../auth/auth-helper';
@Injectable({
  providedIn: 'root',
})
export class TelemetryService {
  private faro: any;

  constructor() {
    this.initializeFaro();
  }

  private initializeFaro() {

    this.faro = RumInit({
      app: {
        name: environment.MopRumAppName,
        version: '1.0.0',
        environment: environment.ENVIRONMENT,
      },
      apiKey: environment.MopRumApiKey,
      useSendBeacon: true,
      sso_user: true,
      ignoreUrls: ['.*localhost.*', '.*pensieve.*'],
      instrumentations: {
        interactions: false,
        console: {
          disabledLevels: [LogLevel.TRACE, LogLevel.INFO, LogLevel.DEBUG],
        },
      },
      debug: false,
      traceLog: false,
      propagateTraceHeaderCorsUrls: [new RegExp('.*api.*')],
    
  });

    const user = AuthHelper.user;
    this.faro.api.setUser({
      id: user?.userId.toString(),
      email: user?.emailAddress,
      username: user?.username,
    });
    /*// Capture and set screen size after initialization
    const screenSize = `${window.screen.width}x${window.screen.height}`;
    this.faro.api.setGlobalAttributes({
      screen_size: screenSize,  // Set screen size as a global attribute for all events
    });*/
  }

  public logScreenSize() {
    const screenSize = `${window.screen.width}x${window.screen.height}`;
    console.log('Logging screen size:', screenSize);
    // Ensure the message is passed as an array
    this.faro.api.pushLog(['screen-size', screenSize], { // Pass array of strings
      level: LogLevel.INFO,
          labels: {
            screen_size: screenSize,  // Add screen size as label
          },
   });
}

// File event logging dynamically
  public logFileEvent(eventType: string, fileCount: number) {
    console.log('Logging file event:', eventType, 'with file count:', fileCount);

    // Dynamically add file event info on file-related actions
    this.faro.api.pushLog(['file-event', eventType], {
      level: LogLevel.INFO,
      labels: {
        file_event_type: eventType,  // Add file event type as label
        file_count: fileCount,       // Add file count as label
      },
    });
  }
  
}
