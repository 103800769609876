<div class="sidebar" [class.sidebar-visible]="toggleMenu()">
    <a [routerLink]="['workorders']" routerLinkActive="active">
        <mc-icon icon="clipboard-text"></mc-icon>
        <span class="m-l-8" i18n>Work Orders</span></a>
    <a [routerLink]="['reports']" routerLinkActive="active">
        <mc-icon icon="folder"></mc-icon>
        <span class="m-l-8" i18n>Reports</span></a>
    <footer>
        &#9400;A.P Moller-Maersk
    </footer>
</div>